import React from 'react'
import Img from 'gatsby-image'

const EventCard = ({ event }) => {

	const daysTillEvent = calcDaysTillEvent(event.date)
	const todayDate = Date.now()
	const eventDate = new Date(event.date)

	return (
        <>
            {eventDate > todayDate && // Kinda hacky method to filter out dates older than today...
                <div className="event-card">
                	<div className="columns">
                		<div className="column is-4">
                			<div className="event-card-image">
                				<Img fluid={event.image.fluid} alt={event.title} />
                			</div>
                		</div>
                		<div className="column is-8">
                			<div className="event-card-content">
                				<h1 className="title">{event.title}</h1>
                				<h2 className="subtitle">{formatDate(event.date)}
                					{
                						daysTillEvent <= 10 && (
                							<span className="has-text-danger has-text-weight-bold">&nbsp;&nbsp;<i className="ri-time-line align-icon"></i>&nbsp;&nbsp;Happening soon!</span>                						)
                					}
                					<br /><span className="is-size-6 is-italic">{event.location}</span>
                				</h2>
                				<p>{event.description.content[0].content[0].value}...</p>
                			</div>
                		</div>
                	</div>
                	<hr />
                </div>
            }
        </>
	)
    
}

export default EventCard

function formatDate(date) {
	var formatDate = new Date(date)
	var options = { weekday: `long`, year: `numeric`, month: `long`, day: `numeric`, hour: `numeric`, minute: `numeric` }
	return (formatDate.toLocaleDateString(`en-GB`, options))
}

function calcDaysTillEvent(date) {
	const date1 = new Date(date)
	const date2 = Date.now()
	const diffTime = Math.abs(date2 - date1)
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
	return diffDays   
}