import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/common/layout'
import SEO from '../components/common/seo'
import EventCard from '../components/events/eventCard'

const EventsPage = ({ data }) => {
	const events = data.events.edges
	const pageDetails = data.eventsDeets.edges[0].node

	var today = new Date()
	const newEvents = events.filter(event => new Date(event.node.date) > today)

	return (
		<Layout pageTitle={pageDetails.title}>
			<SEO
				title={pageDetails.title}
				description={pageDetails.description}
				img={pageDetails.shareImage.file.url}
			/>

			{newEvents.length > 0 ?
				newEvents.map((event) =>
					<EventCard event={event.node} key={event.node.title} />
				)
				:
				<div className="has-text-centered">
					<h1 className="has-text-weight-bold is-size-2">
					No upcoming events.<br/>Check back again soon!
					</h1> <br />
					<Link to="/" className="has-text-weight-bold">Go Home <span className="icon align-icon"><i className="ri-home-2-line" /></span></Link>
				</div>
			}
			
		</Layout>
	)
}

export default EventsPage

export const eventquery = graphql`
  query EventQuery{
    events:allContentfulEvent(sort: {fields: date}) {
      edges {
        node {
          title
          date
          location
          image {
            fluid(maxWidth:300) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            content {
              content {
                value
              }
            }
          }
        }
      }
    }
    eventsDeets:allContentfulPageDetails(filter: {title: {eq: "Events"}}) {
			edges {
				node {
					title
					description
					shareImage {
						file {
							url
						}
					}
				}
			}
		}
  }
`